export default {
  balance: 'Balance',
  recharge: 'Recharge',
  coupon: 'Coupon',
  bill: 'Monthly Bill Fee',
  month: 'Month',
  days: ' Days Left',
  renew: 'Renew',
  expiredDays: '',
  expire: ' expires',
  freePackage: 'Free Package',
  noExpired: 'Permanently Available',
  billDetail: 'Billing Details',
  pkgLabel: 'Profiles: ',
  users: 'Users:',
  additionUsers: 'Additional Users:',
  activate: 'Subscribe',
  change: 'Change Plan',
  preferential: '※ Discount extravaganza, event duration: valid only from 2023.9.5 to 2023.10.10.',
  changeUsers: 'Change Users',
  statistics: 'Statistics',
  todayOpen: 'Open Times Today',
  openCountComment:
    'The open times = total profiles * {count}, if exceeded, which cannot be opened on the day and will be restored on the next day.',
  ordersNotPay: 'Unpaid Orders (USDT)',
  moneyUnit: '¥',
  unit: '',
  paymentOrder: 'Pay',
  paymentOrderDialog: 'Payment Order',
  paid: 'Payment Completed',
  wechatPay: 'Wechat Pay',
  balancePay: 'Balance',
  alipay: 'Alipay',
  rechargePay: 'Recharge',
  withdrawPay: 'Withdraw and recharge',
  time: 'Time',
  payMoney: 'Amount',
  payType: 'Payment Method',
  accountBalance: 'Balance',
  payRemark: 'Details',
  additionErrMsg: 'The free package does not support modify, please subscrip the paid package first.',
  paySuccess: 'Success',
  changeInfo: '<strong>Package</strong>(Original: Browser Profiles {browserCount} Users {users})',
  pkgChangeLabel: 'Package',
  pkgChangeLabel2: 'Original: Browser Profiles ',
  pkgChangeLabel3: 'Users ',
  pkgBrowsers: 'Profiles: {count}',
  pkgUsers: 'Users: {count}',
  pkgUnit: '/Month',
  activateMonths: 'Cycle',
  useBalance: 'Balance',
  balanceAssets: 'Balance: ¥ {balance}',
  paypalTips: 'Only Supports Overseas PayPal Account',
  totalBrowsers: 'Total Profiles: {count}',
  pkgExpired: 'Expiration Date: ',
  pkgOpenCountTips:
    'Note: The open times = {browsers} x {maxCount}, if exceeded, which cannot be opened on the day and will be restored on the next day.',
  pkgOpenEnvCountTips:
    'Note: Each phone profile has a maximum of {maxCount} resets per month. If the limit is exceeded, no further resets can be performed for the rest of the month, and the reset count will reset the following month.',
  freePkgTips: 'Note: Each user is entitled to 6 hours of free use, beyond which additional charges apply!',
  shouldPay: 'Subtotal: ',
  pkgCommentsLabel1: 'Fee Calculation Formula:',
  pkgCommentsValue1: "(New Plan's Cost - Old Plan's Cost)/31 * Days Remaining",
  pkgCommentsLabel2: 'Note: ',
  pkgCommentsValue2: 'System will not refund the reduced package, please be cautious.',
  usersCommentsLabel: 'Increase Num x Cost/31 x Days Remaining',
  usersCommentsValue: 'System will not refund the reduced package or users, please be cautious.',
  changeConfirm: `
              <p class="text-red text-bold">After subscription changes:</p>
              <p class="text-red q-mt-sm">1. All user accounts will be disabled, please go to "Users" to enable them again!</p>
              <p class="text-red q-mt-sm">2. The system will not refund and effect immediately.</p>
              <p class="text-red q-mt-sm">3. Browser other than the number of packages will not be deleted, but will be forbiden to open.</p>
              <p class="q-mt-sm">Confirm to change?</p>
              `,
  environmentChangeConfirm: `The number of phone profiles after downgrading the subscription plan is less than the current number of created phone profiles. Unable to downgrade the plan. Please follow the instructions below to disable the excess phone profiles and try again!
   <p class="q-mt-sm text-bold"> Phone Profiles -> More -> Disable profiles in bulk</p>
  `,
  goDisableProfile: 'Disable Profiles',
  activateSuccess: 'Success',
  changeSuccess: 'Success',
  calculateError: 'Amount calculation error, please try again.',
  couponDialog: 'Coupon',
  myCoupon: 'My Coupon',
  usedCoupon: 'Used',
  couponType: 'Type',
  couponStatus: 'Status',
  couponCode: 'Coupon Code',
  couponRemark: 'Remark',
  cashCoupon: 'Cash',
  discountCoupon: 'Discount',
  couponStatus0: 'Unused',
  couponStatus1: 'Used',
  couponValue: 'Coupon Value',
  couponValueFormat: '¥ {value}',
  couponCreatedTime: 'Release Date',
  couponExpired: 'Expired',
  couponState: 'Status',
  couponExpense: 'With Coupons',
  couponExpensePlaceholder: 'Coupon code',
  changeUsersDialog: 'Change Additional Users',
  additionalUsers: 'Increase Users',
  assignedUsers: 'Assigned ',
  totalUsers: 'Total ',
  increase: 'Increase',
  reduce: 'Reduce',
  userCost: 'Cost:',
  userCostUnit: '/User/Month',
  extraUsers: 'Additional Users: ',
  extraUsers2: ', ',
  reduceTips:
    'Note: When reducing users, if remaining users (total-used) is less than users to be reduced, all user accounts will be disabled after changed, please go to "Users" to enable them again.',
  totalExtraUsers: 'Total Users: ',
  usersRule1: 'Should greater than 0',
  usersRule2: 'Should less than 10000',
  usersRule3: 'The reduction cannot be greater than the current number of additional users',
  reduceUsersConfirm: 'System will not refund if users reduced and effect immediately, please be causious!<br/>Need to opreate or not?',
  changeUsersSuccess: 'Success',
  originalPrice: 'Original Price',
  finalPay: 'Now',
  cycleMonth: 'Month',
  cycleMonths: 'Months',
  cycleMonths3: 'Months',
  cycleMonths6: 'Months',
  cycleMonths12: 'Months',
  cycleMonths24: 'Months',
  confirmActivate: 'Confirm',
  confirmChange: 'Confirm',
  confirm: 'Confirm',
  orderCreatedTime: 'Order Time',
  orderMoney: 'Order Amount',
  orderState: 'Order Status',
  notPay: 'Unpaid',
  hasPaid: 'Paid',
  rechargePlaceholder: 'Please fill in the amount',
  rechargeAmount: 'Amount',
  minAmount: 'Min Recharge Amount: {amount}',
  rechargeRule1: 'Please fill in the amount which is a number',
  rechargeRule2: 'Min Recharge Amount ',
  rechargeRule3: 'The minimum unit of recharge amount is cents',
  pkgDetail: 'Package Details',
  extraAddUsers: 'Additional Users: {count}',
  renewCycle: 'Renewal Cycle',
  youhui: 'Discounted: ',
  renewExpired: 'Expiration Date After Renew: ',
  renewComments: '(Package Cost + Additional Users Cost) x Months',
  renewEnvironmentComments: 'Package Cost x Months',
  confirmRenew: 'Confirm',
  renewSuccess: 'Success',
  orderType: 'Order Type',
  orderType1: 'Recharge',
  orderType2: 'Subscribe',
  orderType31: 'Subscribe',
  orderType33: 'Change Plan',
  orderType20: 'Buy Phone',
  orderType21: 'Renew Phone',
  orderType22: 'Monthly Computing Power',
  orderType22Rmark: 'Buy Monthly Compute, {type}, {name}, {num} units, for {month} month(s)',
  orderType23Rmark: 'Compute Renewal: {name}, Amount: {money}, Months: {month}',
  orderType23: 'Monthly Computing Power',
  orderType3: 'Change Plan',
  orderType4: 'Renew',
  orderType32: 'Renew',
  orderType8: 'Update Users',
  orderType9: 'Refund and recharge',
  orderType34: 'Temporary Computing Power',
  orderType35: 'Environment time billing',
  orderType35Rmark: 'Environment time billing, amount: {money}',
  orderType34FreeTimeRmark: 'seq: {seq}, Consumed Duration: {minutes} minutes',
  orderType34Rmark: 'Temporary computing power, amount: {money}',
  cycle: 'Cycle',
  pkg: 'Package',
  activatePackage: 'Package {pkg}, Cycle: 1 Month',
  freePackageMsg: '{pkg}, Cycle: {cycle} days',
  activatePackageMonths: 'Package {pkg}, Cycle: {cycle} Months',
  changePlan: 'Before: Package {before}, After: Package {after}',
  renewDetail: 'Package: {pkg}, Months: {months}',
  increaseUsers: 'Increase Users: {count}',
  reduceUsers: 'Reduce Users: {count}',
  autoRenew: 'Auto-renewal',
  enableAutoRenew:
    'Are you sure to enable auto-renewal?<p class="q-mt-sm">Once enabled, if your account has sufficient balance, the system will automatically renew the 1-month package and deduct the corresponding balance 3 days before the expiration of your package!</p>',
  disableAutoRenew:
    'Are you sure to cancel auto-renewal?<p class="q-mt-sm">After canceling auto-renewal, the system will stop detecting whether the package expires and will not automatically renew the package for you!</p>',
  rate: 'Rate: ',
  bankCard: 'Bank Card',
  ratioRule1: 'Cannot be lower than the system default ratio.',
  ratioRule2: 'Cannot be higher than your commission ratio.',
  payWarning: `<p class="text-red">Note: Please use this payment method properly. If we detect any fraudulent or risky payment behavior, your account will be permanently banned and cannot be reinstated. </p>
  <p class="text-red q-mt-sm">We have strict risk control measures in place. Any attempt to commit CVV fraud will result in permanent account banning and data cannot be retrieved.</p>
  `,
  usedTimes: 'Used {arg} times',
  usedDate: 'Used Date',
  orderNum: 'Order No',
  remainingTimes: 'Remaining Times',
  Tobepaid: 'To be paid',
  chinIpbuyTip: 'IP is in Mainland China region, this payment method is not supported!',
  discountCoupons: 'Only monetary coupons are allowed, discount coupons are not permitted!',
  discount: 'Discount Coupon: ',
  model: 'Device Model',
  buyingCycle: 'Buying Cycle',
  buyTime: 'Duration',
  buyNum: 'Quantity',
  enterBuyNum: 'Enter quantity',
  airwallexTip:
    'Bank card payment is only supported for amounts of $10 and above. Please recharge your account with $10 before using the balance for payment.',
  browserBill: 'Browser Bill',
  computingBill: 'Cloud Phone Bill',
  phoneComputingPower: 'Phone Profile Computing Power',
  monthlyComputing: 'Monthly Computing Power: ',
  todaynewPhone: "Today's one-click new device Count",
  todaynewPhoneTip:
    'Maximum setup attempts per day = Total number of profiles x {num}. Exceeding the total count prevents setups for the day; resets the next day.',
  monthAdditionalComputing: `This Month's Power Cost: `,
  phoneEnvironment: 'Phone Profile',
  environmentNum: 'Number of Profiles:',
  detail: 'Details',
  planName: 'Plan {name}',
  timeFree: 'Free',
  remainingFreeTime: 'Remaining free time: '
}
