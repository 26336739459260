export default {
  authenticationTip:
    'BitBrowser users must undergo enterprise or personal authentication per regulations from the ministry of industry and information technology. Information provided cannot be modified after successful authentication and will only be used for authentication purposes.',
  enterpriseCertification: 'Enterprise Certification',
  enterpriseName: 'Enterprise Name',
  pleaseEnterpriseName: 'Please enter the enterprise name',
  creditCode: 'Unified Social Credit Code',
  pleaseCreditCode: 'Please enter the unified social credit code',
  pleaseCreditCode1: 'Please enter the correct social credit code',
  pleaseIdCard: 'Please enter the ID card number',
  pleaseIdCard1: 'Enter the correct Chinese ID card',
  legalPerson: 'Legal Person Information',
  idCard: 'ID Card Number',
  pleaseLegalPersonName: "Please enter the legal person's name",
  pleaseLegalPersonNameTip: "The legal person's name can be up to 50 characters",
  companyNameMax: 'The enterprise name can be up to 50 characters',
  enterprise: 'Enterprise',
  authentication: 'Authentication Management',
  Personal: 'Personal Authentication',
  certificationSuccessful: 'Certification Successful',
  certificationFailed: 'Certification Failed',
  personalTip: 'Please enter the correct name, document type, and document number',
  personalTip1: 'Please enter the real name',
  personalTip2: 'Please select the document type',
  personalTip3: 'Please enter the document number',
  personalTip4: 'Certification Steps:',
  personalTip5: "Use Alipay's 'Scan' feature to scan the generated QR code. Click 'Start Certification'.",
  personalTip6: 'Follow the instructions in Alipay to complete the face recognition authentication.',
  personalTip7:
    "After completing the authentication, click 'Certification Completed'. It will display 'Certification Successful' in the personal authentication panel!",
  personalTip7_1: "After completing the authentication, click 'Certification Completed'.",
  nextStep: 'Next',
  Back: 'Back',
  personalTip8: 'Certification Completed',
  personalTip9: 'Please enter the real name',
  personalTip10: 'Name cannot exceed 10 characters',
  personalTip11: 'Please enter the document number',
  personalTip12: 'Please enter the correct document number',
  personalTip13: 'The document number length should not exceed 30 characters',
  IDtype: 'Document Type',
  idNo: 'Document Number',
  name: 'Name',
  authenticationFailed: 'Authentication Failed. Please re-authenticate!',
  authenticationFailed1: 'Authentication Failed. Please confirm the information and re-authenticate!',
  certified: 'Pending Certification',
  toAuthen: 'Go to Authenticate',
  enterpriseSuccess: 'Enterprise Certification Successful',
  legalPersonNameTip:
    "The legal person's name is automatically recognized based on the uploaded enterprise business license and cannot be modified. If the recognition is inaccurate, please contact customer service.",
  underReview: 'Enterprise certification is under review and will be completed within 30 minutes. Please be patient!',
  agentVerification: 'Agent Verification',
  agentName: 'Agent Name',
  idCard: 'ID Card Number',
  clickGoCertification: "Click 'Go to Authenticate' to use your Alipay for face recognition authentication",
  agentTip:
    "Note: Only after successfully completing the agent's face recognition authentication, can you proceed to the next step of 'Enterprise Information Verification', which mainly includes: enterprise name, unified social credit code, legal person name, legal person ID card number, etc.",
  authenticationCompleted: 'Authentication Completed',
  enterpriseVerification: 'Enterprise Information Verification',
  enterLegalIdCard: "Enter the legal person's ID card number",
  goEnterpriseCertification:
    "Click 'Go to Authenticate' to verify the enterprise name, unified social credit code, legal person's name, legal person's ID card number, etc. If authentication is successful, you can proceed to the next step of enterprise certification",
  auditFailed: 'Audit Failed',
  selectEnterpriseAuthorization: 'Select the method for enterprise certification',
  paymentTitle: 'Payment to Enterprise Corporate Account',
  paymentTitleTip:
    'Use the enterprise bank account to initiate reverse payment to the third-party platform bank account. After successful payment, the certification is completed.',
  paymentTip1: 'Enterprise Corporate Payment Process',
  paymentTip2: "Click the 'Get Account' button below to get the account information for corporate payments.",
  paymentTip3: 'Please use the corporate bank account to make the specified amount of payment to the account obtained below.',
  paymentTip4:
    'Note: You must use the corporate bank account to make the payment, and the actual amount received must be consistent with the amount obtained below, otherwise the certification will fail.',
  paymentTip5: "After the payment is completed, click 'Payment Completed' and then wait for the certification. It takes about 30 minutes.",
  getAccount: 'Get Account',
  accountInformation: 'Account Information',
  recipientName: 'Recipient Company Name',
  recipientAccount: 'Recipient Account',
  recipientBank: 'Recipient Bank',
  paymentAmount: 'Payment Amount',
  legalAuthorization: 'Corporate Legal Authorization Signature',
  legalAuthorizationTip:
    'Notify the corporate legal person and start the corporate authorization signature. After completing the signature task, the certification is completed.',
  legalAuthorizationProcess: 'Corporate Legal Authorization Signature Process',
  legalAuthorizationProcess1:
    "Enter the legal person's mobile number, get the verification code, and enter the verification code after successful acquisition.",
  legalAuthorizationProcess2:
    "Click the 'Get Signing' button to get the link/QR code (copy supported). Open the link address with a mobile browser and follow the steps to authenticate.",
  legalAuthorizationProcess3:
    "If the signature authorization authentication is completed, please click the 'Authorization Completed' button.",
  legalAuthorizationProcess4: "If the signing link expires, please re-enter the legal person's mobile number to get the signing link.",
  getSigning: 'Get Signing',
  signingLink: 'Signing Link',
  paymentCompleted: "If the payment is completed, please click the 'Payment Completed' button",
  authorizationCompleted: "If the signature authorization authentication is completed, please click the 'Authorization Completed' button",
  enterpriseCompleted: 'Congratulations, Enterprise Certification Completed',
  paymentDone: 'Payment Completed',
  authorizationCompletedBtn: 'Authorization Completed',
  legalPhone: "Legal Person's Mobile Number",
  legalPersonName: "Legal Person's Name",
  awaitCompletedPayment: "Corporate public account payment pending completion. Please click 'Payment Completed' after completion.",
  authorisationCertification:
    "Signature Authorization Authentication pending completion. Please click 'Authorization Completed' after completion.",
  download: 'Download'
}
