export default {
  workbench: 'Workbench',
  chuhai2345: '2345 Navigation',
  localServer: 'Local Server',
  workbenchDisable: 'Disable',
  workbenchTips: 'Use the "Local Server" or "Disable" to save traffic.',
  abortImg: 'Abort Image',
  abortImgTips:
    'In order to prevent the inability to display CAPTCHA, it is recommended to set it at 10KB; setting it to 0KB will not load any images!',
  abortMedia: 'Abort Video Autoplay/Payload',
  abortMediaTips: 'When enabled, autoplay and preloading will be disabled, and non-standard formats may be invalid.',
  muteAudio: 'Mute Audio',
  muteAudioTips: 'All video or audio will play in mute mode.',
  stopWhileNetError: 'Stop Opening While Net Error',
  stopWhileNetErrorTips: 'Stop opening the browser if the network is not smooth due to proxy or other reasons.',
  syncTabs: 'Sync Tabs',
  syncTabsTips: 'Synchronize browser opened tabs.',
  syncCookies: 'Sync Cookies',
  syncCookiesTips: 'Synchronize browser cookies to stay logged in.',
  syncIndexedDb: 'Sync IndexedDB',
  syncIndexedDbTips: 'Synchronizing IndexedDB, some sites will use IndexedDB to store login information.',
  syncLocalStorage: 'Sync Local Storage',
  syncLocalStorageTips: 'Synchronizing Local Storage, some sites will use Local Storage to store login information.',
  syncBookmarks: 'Sync Bookmarks',
  syncBookmarksTips: 'Synchronize browser bookmarks.',
  syncAuthorization: 'Sync Saved Autofill',
  syncAuthorizationTips: 'Synchronize saved usernames and passwords in your browser, supported in version 6.0.1 and above.',
  credentialsEnableService: 'Disable Autofill Pop-up',
  credentialsEnableServiceTips: 'If Enabled, Autofill will no longer pop up.',
  syncHistory: 'Sync History',
  syncHistoryTips: 'Synchronize browser access history.',
  syncExtensions: 'Sync Extension Data',
  syncExtensionsTips:
    'Synchronize the extension application data for this window to maintain the login state of the extension application. <span class="text-red">It is not recommended to enable this option when using a cryptocurrency wallet; it is advised to keep extension application data locally!</span>',
  syncUserExtensions: 'Sync Extension Data Across Browsers',
  syncUserExtensionsTips:
    'All browser profiles that have this switch turned on will synchronize the latest extension application data with each other (these profiles will always keep the same extension application data; this can achieve that all these browsers maintain the login status of the extension).',
  clearCacheFilesBeforeLaunch: 'Clear Cache Dir Before Launch',
  clearCacheFilesBeforeLaunchTips: 'Clear all cache files before launch, and do not synchronize the cache saved by the server.',
  clearCookiesBeforeLaunch: 'Clear Cookies Before Launch',
  clearCookiesBeforeLaunchTips: 'Clear cookies before launch, and do not synchronize the cookies saved by the server.',
  clearHistoriesBeforeLaunch: 'Clear History Before Launch',
  clearHistoriesBeforeLaunchTips: 'Clear all the history before launch, and do not synchronize the history saved by the server.',
  randomFingerprint: 'Random Fingerprint Before Launch',
  randomFingerprintTips: 'Generate randomly Fingerprint before launch.',
  allowedSignin: 'Allowed Signin To Chrome',
  allowedSigninTips:
    'Disable to log in to Google websites such as Gmail without sign to Chrome(The login status can be synchronized between different computers).',
  enableBackgroundMode: 'Enable Background Mode',
  enableBackgroundModeTips: 'Disable to stop running in background mode, which can save some system resources.',
  disableGpu: 'Disable GPU',
  disableGpuTips:
    'When enabled, GPU hardware acceleration will be automatically enabled to improve browser performance if the system supports it.',
  settingTips:
    'Tips: The preference value will apply to the corresponding property when creating a new profiles. Modifying the value here will not modify any property of the existing profile, and does not support the use of Local API.',
  settingTips1:
    'When a new profile is created, the corresponding property will be prioritized with the preferred value as the default property, modifying the value here will not modify any of the properties of the existing profile, and does not support the use of Local API.',
  disableTranslatePopup: 'Disable Translate Pop-up',
  disableTranslatePopupTips: 'When enabled, the browser will be prohibited from automatically popping up Google Translate.',
  stopWhileIpChange: 'Stop Opening When IP Changes',
  stopWhileIpChangeTips: 'Stop opening the browser when the current IP is inconsistent with the IP of the last opened browser.',
  resetLastIp: 'Reset IP',
  resetLastIpConfirm: 'Confirm to reset the last IP of the profile?',
  resetLastIpSuccess: 'The IP reset is complete, save the configuration and it will take effect!',
  disableSyncTips:
    'Note: Version 5.0.9 and below no longer support synchronization feature. If you require the following synchronization functionality, please upgrade to the latest version for use!',
  disableSyncTips1:
    'After turning on the following synchronization items, the corresponding data will be synced to the cloud (facilitating cross-device usage/collaboration). When sharing/transferring a profile, the data from the turned-on items will also be synced to the recipient. Please proceed with caution.',
  syncExtensionsWarning:
    '<p class="text-red">Using a cryptocurrency wallet, it is not recommended to enable this option. It is advised to keep extension application data locally. Are you sure you want to proceed?</p>',
  syncUserExtensionVersionTips: 'Version 6.0.1 and above support this feature.',
  abortImgSizePrefix: 'Block images over',
  abortImgSizeSuffix: 'to save traffic.',
  stopWhileCountryChange: 'Cease Access For Changed IP Location',
  stopWhileCountryChangeTips:
    'If the current IP corresponds to a different country/region compared to the last time it was opened, cease browser access.',
  clearCacheWithoutExtensions: 'Clear Cache Before Startup (Retain Extension Data)',
  clearCacheWithoutExtensionsTips:
    'Delete cache files before launching the browser (retain extension data) and do not synchronize with the server-saved cache.',
  syncPaymentsAndAddress: 'Sync Saved Payment Methods and Addresses',
  syncPaymentsAndAddressTips: 'Sync payment methods and addresses saved in the browser profile.',
  hide: 'Collapse',
  more: 'Show more',
  disableNotifications: 'Disable Website Notifications',
  disableNotificationsTips: 'The website notifications pop-ups will be blocked.',
  disableClipboard: 'Disable Website Clipboard Access',
  disableClipboardTips: 'Prevents websites from accessing clipboard content for enhanced security.',
  memorySaver: 'Memory Saver',
  memorySaverTips:
    'When on, BitBrowser frees up memory from inactive tabs. This gives active tabs and other apps more computer resources and keeps BitBrowser fast.'
}
