/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */

import { LocalStorage, SessionStorage } from 'quasar'
import { getUserInfo, login, emailLogin, userLogout, getKefuImg } from 'api/user'
import { getLoginSid, getToken, markTokenCycle, removeToken, saveLoginSid, setLocalToken, TOKEN_KEY } from 'utils/token'

import { getValueList } from 'src/api/settings'
import router, { resetRouter } from 'src/router'
import { FORCE_UPDATE_PASSWORD_SESSION } from 'src/static-data/constValues'
import { isString } from 'utils/validate'
import { getLocalTimeZone } from 'utils/timezone'

const state = () => ({
  token: getToken(),
  sid: getLoginSid(),
  username: '',
  avatar: 'https://i.gtimg.cn/club/item/face/img/9/15919_100.gif',
  balance: 0, // 余额
  userType: null, //用户身份1，2，3，4
  roleId: null, // 角色ID
  userInfo: {}, // 完整的userInfo对象
  tipMsg: '', // 到期提醒
  tipPhoneMsg: '', // 手机到期提示
  kefuImg: '', // 客服二维码
  timezone: LocalStorage.getItem('timezone') || getLocalTimeZone(),
  syncTasks: new Map(), // {seq: Set([...taskId])}
  // showSyncUserExtensions: '0', // '1' | '0' 是否展示“跨窗口同步扩展数据”
  symbolList: null, // 币种列表
  globalvalueSetting: {
    browserBaseSetting: '',
    badge: '',
    bookmarks: '',
    websitesCredentials: '',
    ipWhitelist: '',
    RpaConcurrent: '',
    RpaRunningStrategy: ''
  },
  envType: ''
})
const getters = {
  token: state => state.token,
  sid: state => state.sid,
  username: state => state.username,
  avatar: state => state.avatar,
  balance: state => state.balance,
  userType: state => state.userType,
  roleId: state => state.roleId,
  userInfo: state => state.userInfo,
  tipMsg: state => state.tipMsg,
  tipPhoneMsg: state => state.tipPhoneMsg,
  isMainAccount: state => state.userType === 1,
  kefuImg: state => state.kefuImg,
  // 过滤掉riskLevel === 1 时，需要屏蔽的权限
  permissions: state =>
    state.userInfo.riskLevel === 1
      ? state.userInfo.menuCodes.filter(
          item => !['recycle:delete:selection', 'recycle:delete:all', 'browser:delete:forever'].includes(item)
        )
      : state.userInfo.menuCodes,
  isAdmin: state => state.userInfo.roleId === 'admin',
  syncTasks: state => Array.from(state.syncTasks.keys()),
  showSyncUserExtensions: state => state.showSyncUserExtensions,
  isFreeAccount: state => state.userInfo.packageId === 'p1',
  isSecondAuthStatusShow: state => state.userInfo.secondAuthStatusShow === 1,
  symbolList: state => state.symbolList,
  riskLevel: state => state.userInfo.riskLevel,
  globalvalueSetting: state => state.globalvalueSetting,
  timezone: state => state.timezone,
  envType: state => state.envType
}
const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
    setLocalToken(token)
  },
  SET_SID(state, sid) {
    state.sid = sid
    saveLoginSid(sid)
  },
  SET_USERNAME(state, username) {
    state.username = username
  },
  SET_AVATAR(state, avatar) {
    state.avatar = avatar
  },
  SET_BALANCE(state, balance) {
    state.balance = balance
  },
  SET_ROLEID(state, roleId) {
    state.roleId = roleId
  },
  SET_USER_TYPE(state, type) {
    state.userType = type
  },
  SET_USERINFO(state, info) {
    state.userInfo = info
  },
  SET_TIP_MSG(state, msg) {
    state.tipMsg = msg
  },
  SET_PHONE_TIP_MSG(state, msg) {
    state.tipPhoneMsg = msg
  },
  SET_KEFU(state, kefuImg) {
    state.kefuImg = kefuImg
  },
  SET_GLOBALVALUE_SETTING(state, info) {
    if (typeof info.type === 'string') {
      state.globalvalueSetting[info.type] = { ...state.globalvalueSetting[info.type], ...info.obj }
    } else {
      state.globalvalueSetting = info
    }
  },
  // 监听task sync事件
  SET_SYNC_TASK(state, task) {
    if (task.type === 'add') {
      if (state.syncTasks.has(task.seq)) {
        state.syncTasks.get(task.seq).add(task.taskId)
      } else {
        state.syncTasks.set(task.seq, new Set([task.taskId]))
      }
    } else {
      const itemSet = state.syncTasks.get(task.seq)
      itemSet.delete(task.taskId)
      if (!itemSet.size) state.syncTasks.delete(task.seq)
    }
  },
  // 刷新后获取所有tasks
  ALL_SYNC_TASKS(state, tasks) {
    if (!tasks || !tasks.length) {
      state.syncTasks.clear()
    } else {
      for (let item of tasks) {
        this.commit('user/SET_SYNC_TASK', { type: 'add', ...item })
      }
    }
  },
  // SET_SYNC_USER_EXTENSIONS(state, payload) {
  //   state.showSyncUserExtensions = payload
  // },
  SET_SYMBOL_LIST(state, symbolList) {
    state.symbolList = symbolList
  },

  // 防止刷新的时候没数据，存在LocalStorage
  SET_TIMEZONE(state, timezone) {
    state.timezone = timezone
    LocalStorage.set('timezone', timezone)
  },
  SET_ENVTYPE(state, envType) {
    state.envType = envType
    LocalStorage.set(state.userInfo.id + 'envType', envType)
  }
}
const actions = {
  async login({ commit, rootGetters }, userInfo) {
    let data
    commit('SET_ENVTYPE', '')
    if (userInfo.mail) {
      data = await emailLogin(userInfo)
    } else {
      data = await login(userInfo)
    }
    const token = data.access_token
    LocalStorage.set('loginApiErrorLog', 1)
    if (token) {
      commit('SET_TOKEN', token)
      commit('SET_SID', data.sid)
      router.replace('/')
      markTokenCycle()
    } else {
      const err = `登录接口异常，未正确返回${TOKEN_KEY}...`
      throw new Error(err)
    }
  },
  /**
   * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  async getUserInfo({ commit, dispatch, state }) {
    const data = await getUserInfo()
    let { userName, avatar, roleId, userType, balance, tipMsg, tipPhoneMsg, timeZone } = data

    // 保存整个userInfo
    commit('SET_USERINFO', data)

    // 保存余额
    commit('SET_BALANCE', balance)
    const username = userName

    if ((username && !isString(username)) || (avatar && !isString(avatar))) {
      const err = 'getUserInfo核心接口异常，请检查返回JSON格式是否正确'
      throw err
    } else {
      if (username) commit('SET_USERNAME', username)
      if (avatar) commit('SET_AVATAR', avatar)
      if (roleId) commit('SET_ROLEID', roleId)
      if (userType) commit('SET_USER_TYPE', userType)

      if (timeZone) {
        commit('SET_TIMEZONE', timeZone)
      } else {
        commit('SET_TIMEZONE', getLocalTimeZone())
      }

      commit('SET_TIP_MSG', tipMsg || '')
      commit('SET_PHONE_TIP_MSG', tipPhoneMsg || '')
    }
  },
  // 检查下是否存在userInfo，不存在则请求一次
  async checkUserInfo({ state, dispatch }) {
    if (!state.userInfo.id) {
      await dispatch('getUserInfo')
      const token = getToken()
      if (token) {
        // 兼容下cookie的token，未重新登录的用户需要同步设置下cookie
        setLocalToken(token)
      }
    }
  },
  async getGlobalValueList({ commit }) {
    const data = await getValueList({
      globalValueTypelist: [
        'browserBaseSetting',
        'badge',
        'bookmarks',
        'websitesCredentials',
        'ipWhitelist',
        'RpaConcurrent',
        'RpaRunningStrategy'
      ]
    })
    commit('SET_GLOBALVALUE_SETTING', data)
  },
  async resetAll({ commit }) {
    router.replace({ name: 'Login' })
    commit('SET_TOKEN', '')
    commit('SET_SID', '')
    commit('SET_USERNAME', '')
    commit('SET_BALANCE', 0)
    commit('SET_USER_TYPE', null)
    commit('SET_ROLEID', null)
    commit('SET_USERINFO', {})
    resetRouter()
    removeToken()
    commit('routes/setRoutes', [], { root: true })
    commit('SET_GLOBALVALUE_SETTING', {
      browserBaseSetting: '',
      badge: '',
      bookmarks: '',
      websitesCredentials: '',
      ipWhitelist: '',
      RpaConcurrent: '',
      RpaRunningStrategy: ''
    })
    SessionStorage.remove(FORCE_UPDATE_PASSWORD_SESSION)
  },
  setAvatar({ commit }, avatar) {
    commit('SET_AVATAR', avatar)
  },
  // 弃用
  getKefuImg({ state, commit }) {
    // 当前有客服图片时，不重新获取
    if (!!state.kefuImg) return
    getKefuImg().then(res => {
      if (res && res.code === 1) {
        commit('SET_KEFU', res.weixinimage)
      }
    })
  }
}
export default { state, getters, mutations, actions }
