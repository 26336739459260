import { addRouter } from 'src/router'
import { getRouterList } from 'api/router'
import { convertRouter } from 'utils/routes'
import { isArray } from 'utils/validate'
import { LocalStorage, Notify } from 'quasar'

import store from '../index'
const state = () => ({
  routes: [],
  activeName: ''
})
const getters = {
  routes: state => state.routes,
  activeName: state => state.activeName
}
const mutations = {
  setRoutes(state, routes) {
    state.routes = routes
  },
  /**
   * @description 修改Meta
   * @param {*} state
   * @param options
   */
  changeMenuMeta(state, options) {
    function handleRoutes(routes) {
      return routes.map(route => {
        if (route.name === options.name) Object.assign(route.meta, options.meta)
        if (route.children && route.children.length) route.children = handleRoutes(route.children)
        return route
      })
    }

    state.routes = handleRoutes(state.routes)
  },
  /**
   * @description 修改 activeName
   * @param {*} state
   * @param activeName 当前激活菜单
   */
  changeActiveName(state, activeName) {
    state.activeName = activeName
  }
}
const actions = {
  /**
   * @description 多模式设置路由
   * @param {*} { commit }
   * @param mode
   * @returns
   */
  async setRoutes({ commit }) {
    // 后端返回有权限的路由list
    let res = await getRouterList()
    // English的话，替换掉文档地址
    let list = res.list.map(item => {
      const locale = LocalStorage.getItem('locale')
      if (item.path.includes('doc.bitbrowser.cn') && locale === 'en') {
        item.path = item.path.replace('doc.bitbrowser.cn', 'doc.bitbrowser.net')
      }
      return item
    })
    // 如果有云手机环境，要加一个云手机按天类型的页面
    for (let i = 0; i < list.length; i++) {
      if (list[i].name === 'environment') {
        list.splice(i + 1, 0, {
          name: 'environmentdaily',
          path: 'environmentdaily',
          hidden: false,
          component: 'environment/daily',
          meta: {
            title: '云手机环境',
            icon: 'user',
            noCache: false
          }
        })
      }
    }

    if (!isArray(list)) Notify.create({ message: '路由格式返回有误！', type: 'negative' })

    // 返回的动态路由list转为vue-router组件
    let profile = {
      alwaysShow: true,
      name: 'Profile',
      path: 'profile',
      hidden: false,
      component: 'profile/routerView',
      meta: {
        title: '个人中心',
        icon: 'user',
        noCache: false
      },
      children: [
        {
          name: 'ProfileIndex',
          path: 'index',
          hidden: false,
          component: 'profile/index.vue',
          meta: {
            title: '认证',
            icon: 'user',
            noCache: false
          }
        },
        {
          name: 'Authentication',
          path: 'authentication',
          hidden: false,
          component: 'profile/authentication/index.vue',
          meta: {
            title: '认证',
            icon: 'user',
            noCache: false
          }
        }
      ]
    }

    list.push(profile, {
      name: 'computingPower',
      path: 'computingPower',
      hidden: false,
      component: 'computingPower/index',
      meta: {
        title: '算力管理',
        icon: 'user',
        noCache: false
      }
    })

    const asyncChildren = convertRouter(list)
    // 添加首页
    let hasHomeRoter = list.find(i => i.name === 'Dashboard')
    if (!hasHomeRoter) {
      asyncChildren.unshift({
        name: 'Dashboard',
        path: 'dashboard',
        component: () => import('pages/dashboard/index.vue'),
        meta: { title: '首页' }
      })
    }
    // 静态路由
    const accessRoutes = []
    if (asyncChildren.length) {
      // 有返回权限的动态路由，添加到路由表
      // 动态路由，接口返回的所有业务路由，均为它的子路由
      accessRoutes.push({
        path: '/',
        name: 'MainLayout',
        component: () => import('layouts/MainLayout.vue'),
        children: asyncChildren,
        redirect: asyncChildren[0].path // 默认跳转到第一个子路由
      })
    }
    // 左侧菜单路由list
    commit('setRoutes', JSON.parse(JSON.stringify(asyncChildren)))
    // 添加远程获取到的路由
    addRouter(accessRoutes)
  },
  /**
   * @description 修改Route Meta
   * @param {*} { commit }
   * @param options
   */
  changeMenuMeta({ commit }, options = {}) {
    commit('changeMenuMeta', options)
  },
  /**
   * @description 修改 activeName
   * @param {*} { commit }
   * @param activeName 当前激活菜单
   */
  changeActiveName({ commit }, activeName) {
    commit('changeActiveName', activeName)
  }
}
export default { state, getters, mutations, actions }
