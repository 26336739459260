export default {
  inviteUrl: 'Ссылка-приглашение',
  qrCodeName: 'QR-код для продвижения {name}',
  generateQrcode: 'Создайте QR-код',
  generateQrcodeTip: 'Используйте свой рекламный QR-код, чтобы направлять пользователей к товарам за вознаграждение.',
  inviteUrlTips: '『Подключите пакет для постоянного отображения вашей ссылки-приглашения』',
  copyUrl: 'Копировать ссылку',
  inviteCode: 'Ваш пригласительный код',
  inviteTips: '『Отображается после получения статуса промоутера』',
  copyCode: 'Копировать пригласительный код',
  overview: 'Обзор данных',
  couponBtn: 'Купон',
  withdraw: 'Вывести средства',
  detailsBtn: 'Подробности продвижения',
  withdrawRecords: 'Записи о выводе средств',
  inviteUsers: 'Приглашенных',
  userUnit: 'человек',
  rewards: 'Общая сумма комиссии',
  moneyUnit: 'юаней',
  withdrawTotal: 'Общая сумма выводов',
  withdrawMoney: 'Доступно для вывода',
  policyText1: 'Механизм вознаграждения за приглашение на {name} запущен! Большие награды ждут вас!',
  policyText2:
    'С помощью механизма вознаграждения за приглашение вы не только можете рекомендовать {name} другим, но и получать щедрые комиссионные вознаграждения для себя!',
  policyTitle: 'Правила вознаграждения за приглашение:',
  policy1: '1. Открыто для всех подписчиков {name}!',
  policy2:
    '2. Скопируйте вашу реферальную ссылку и рекламируйте её. Когда зарегистрированные пользователи перейдут по ссылке и сделают покупку, вы получите высокий процент комиссии от их суммы.',
  policy3Label: 'Вознаграждение:',
  policy3:
    ' <strong>{rate}%</strong> от использования пакета браузера и <strong>{rate1}%</strong> от мобильной среды. Пассивный доход на всю жизнь, без срока ограничения.',
  policy3Tips: '（Пассивный доход на всю жизнь, без ограничения по сроку）',
  policy4Label: 'Дополнительное вознаграждение:',
  policy4:
    'За приглашение в общей сложности 5 пользователей вы получите 50-юаневый купон; за приглашение в общей сложности 10 пользователей вы получите дополнительный 50-юаневый купон; за приглашение в общей сложности 20 пользователей вы получите дополнительный 100-юаневый купон.',
  policy51: 'Доступная сумма для вывода должна быть больше, чем',
  policy52: ', а также не менее',
  policy53: 'приглашенных пользователей, которые совершили покупку пакета, чтобы подать заявку на вывод средств.',
  policy6: 'Если пользователь отменяет подписку или возвращает средства, вознаграждение за приглашение будет отменено.',
  policy7:
    'Одно и то же устройство/IP/платежный счет генерирует связанного с ним подчиненного пользователя, навсегда прекращая разделение комиссии с вышестоящим звеном!',
  policy7_1:
    'Одно и то же устройство/IP/платежный счет генерирует связанного с ним подчиненного пользователя, навсегда прекращая разделение комиссии с начальником!',
  policy8:
    'Окончательная интерпретация этой акции принадлежит организатору, если у вас есть вопросы, пожалуйста, свяжитесь со службой поддержки!',
  policy9:
    'Примечание: Для подчиненных пользователей, связанных с одним и тем же устройством/IP/платежным аккаунтом, будет навсегда прекращено разделение комиссии с вышестоящим звеном!',
  copySuccess: 'Успешно скопировано',
  withdrawMsg: "Доступная для вывода сумма должна быть не менее <span class='text-red'>{money}</span> {symbol}!",
  withdrawMsg2:
    "Необходимо, чтобы как минимум <span class='text-red'>{min}</span> непосредственно приглашенных пользователей приобрели платный пакет. В настоящее время всего <span class='text-red'>{max}</span>!",
  detailsDialog: 'Детали продвижения',
  detailTips: 'Вы являетесь главным агентом и можете установить процент комиссии для приглашенных вами пользователей!',
  rewardsTips:
    'Ваши комиссии: {rate}% от потребления нижестоящего пакета, {rate1}% от потребления облачного телефона. Вы получите соответствующие отчисления от прямого потребления ваших нижестоящих пользователей!',
  rewardsTips2:
    'Если приглашенный вами пользователь приглашает других пользователей, то он получит комиссию, установленную вами, а вы получите вознаграждение в размере (сумма покупки × (ваш процент комиссии - процент комиссии подуровня)).',
  users: 'Подчиненные пользователи',
  records: 'Записи о комиссии',
  invitedUsers: 'Общее количество приглашенных: ',
  totalPay: 'Совокупный объем расходов: ',
  totalRewards: 'Сумма комиссии: ',
  createdTimeStart: 'Дата начала регистрации',
  createdTimeEnd: 'Дата окончания регистрации ',
  tradeTimeStart: 'Дата начала сделки',
  pleaseTradeTime: 'Время сделки',
  pleaseCreatedTime: 'Время регистрации',
  tradeTimeEnd: 'Дата окончания сделки',
  minRate: 'Не менее стандартного процента',
  maxRate: 'Не более вашего процента комиссии',
  username: 'Имя пользователя',
  invitedBy: 'Пригласил',
  rewardsRate: 'Доля пакета (Профили браузера)',
  phoneRewardsRate: 'Доля пакета (Профили телефона)',
  totalPayMoney: 'Сумма Купить',
  createdTime: 'Дата регистрации',
  remark: 'Статья покупки',
  payMoney: 'Сумма покупки',
  percent: 'Процент комиссии',
  reward: 'Сумма комиссии',
  tradeTime: 'Дата транзакции',
  saveSuccess: 'Успешно сохранено',
  status0: 'На рассмотрении',
  status1: 'Выплачено',
  status2: 'Отклонено',
  alipay: 'Alipay:',
  bank: 'Банковская карта:',
  balance: 'Баланс центра оплаты',
  requestTime: 'Дата запроса',
  requestMoney: 'Сумма вывода',
  payInfo: 'Информация о платеже',
  status: 'Статус',
  paypal: 'PayPal:'
}
