export default {
  sysDialog: '系统提示',
  forbiddenMobile: '禁止通过手机网页端登录，请使用软件客户端登录或电脑浏览器登录！',
  refresh: '刷新界面，若界面一直出现白屏状态，请按{shortKey}可再次刷新界面！',
  noticeNavBar: '公告：',
  syncError: '同步异常',
  upgradeAvailable: '发现新版本',
  onlineService: '在线客服',
  wechatGroup: '联系我们',
  wechatGroupTips: '点此扫描二维码加入官方交流群',
  iKnow: '我知道了',
  officialWebsite: '官方网站',
  profile: '个人中心',
  settings: '系统设置',
  signOut: '退出登录',
  logAndClose: '退登并关闭',
  wechatGroupDialog: '官方交流群二维码',
  joinGroup: '（点击加群）',
  signOutConfirm:
    '确定退出登录该账号吗？<p class="q-mt-sm">退出登录前，建议手动关闭所有已经打开的浏览器窗口，否则未关闭的窗口将被直接关闭掉且会造成数据同步不成功！</p>',
  signOutConfirmClose:
    '确定退登该账号并关闭软件吗？<p class="q-mt-sm">退登该账号并关闭软件前，建议手动关闭所有已经打开的浏览器窗口，否则未关闭的窗口将被直接关闭掉且会造成数据同步不成功！</p>',
  signOutWeb: '确定退出登录该账号吗？',
  signOutSuccess: '退出登录成功',
  pageRecords: '共 {total} 条',
  gotoPage: '前往',
  page: '页',
  '10PerPage': '10条/页',
  '20PerPage': '20条/页',
  '50PerPage': '50条/页',
  '100PerPage': '100条/页',
  noData: '暂无数据',
  reminder: '温馨提示',
  reminderComments:
    '比特浏览器免费1年多啦，在疫情反复、不断隔离封控、且公司持续没有收入情况下，已经无力承担员工开支、服务器开支，到了捉襟见肘、难以为继的窘境，为了能为广大用户继续提供服务、提升产品，被迫开始收费啦！收费后将永久维持服务不会有中断的可能！望大家理解，希望大家能一如既往的支持！！（为表示对广大用户长久的支持，我们的收费定价比较低，目前为同行售价的1/4-1/8）',
  reminderTips: '注：',
  reminder1: '1. 套餐未到期的用户，套餐到期后，才开始收费。',
  reminder2:
    '2. 可以将套餐变更为适合自己的更低级别的套餐，向下变更套餐不收费，且续费时价格会更低。可以减少员工数或使用套餐自带员工数，也可以减少续费价格！！！',
  reminder3: '为了表示对老客户的支持，所有老客户可以获得一张8折优惠劵，请点击首页按钮“老用户领取８折优惠券”即可领取！',
  dismiss: '不再弹出',
  imgLoadingError: '图片无法加载',
  scanWechat: '微信扫一扫联系客服',
  paymentMoney: '支付金额',
  cny: '（{money}元）',
  moneyUnit: '元',
  waitingForPay: '等待支付完成...',
  syncDialog: '同步数据异常处理',
  syncTips:
    '注意：以下窗口可能因为网络等问题，关闭时同步数据失败！建议切换或关闭代理节点后点击“重试”再次同步！（同步失败可能造成账号退登等问题）',
  syncTips2: '点击“忽略全部”，会忽略掉当前同步失败的数据并不再同步！',
  syncIgnore: '忽略全部',
  usdtMoney: '转账金额：',
  usdtValue: '（{money}元，汇率：{rate}）',
  usdtAddress: '收款地址',
  taxIDPlaceholder: '转账完成后，请输入完整的交易号（TxID，即Transaction ID）',
  taxID: '交易号（TxID）',
  usdtTips: '注意：',
  usdtTips1: '1. 请扫码支付或者使用“收款地址”转账支付，务必输入“转账金额”中精确到小数点的准确数字，否则不会到账！注：',
  usdtTips11: '若转账过程扣除USDT手续费，实际到账金额务必与“该页面的转账金额”一致。',
  usdtTips2: '2. 转账完成后，等待链上确认过程（大约几分钟），确认转账成功后，输入此次订单准确的交易号（TxID），点击“已完成支付”即可！',
  taxIDRule: '请输入TaxID',
  wechatScanPay: '请用微信扫码支付',
  quitConfirm:
    '确认要退出软件吗？<p class="q-mt-sm">退出前，建议手动关闭所有已经打开的浏览器窗口，否则未关闭的窗口将被直接关闭掉且会造成数据同步不成功！</p>',
  quitConfirm:
    '确认要退出软件吗？<p class="q-mt-sm">退出前，建议手动关闭所有已经打开的浏览器窗口，否则未关闭的窗口将被直接关闭掉且会造成数据同步不成功！</p>',

  changeLineConfirm: '确认要切换线路吗？<br/>切换线路时，将会关闭所有浏览器并需要重新登录客户端！',
  clientVersion: '版本：',
  releaseNote: '更新日志：',
  downloadBackgroud: '后台下载',
  upgradeNow: '立即更新',
  upgradeTips: '安装更新时，将会关闭已打开的浏览器，请注意保存数据!',
  install: '安装',
  applyingUpdates: '正在应用更新，请稍后',
  updateError:
    '升级失败，请重启并以管理员方式运行客户端，尝试重新升级。<br/>注：升级成功后，不要再以管理员方式运行客户端，否则可能某些功能失效！',
  uncompressError: '解压升级文件失败',
  updateDetected: '检测到有新内容更新，将刷新页面以应用最新版本。',
  首页: '首页',
  line: '线路',
  goodMorning: '早上好，',
  goodMorning2: '上午好，',
  goodAfternoon: '下午好，',
  goodAfternoon2: '中午好，',
  GoodEvening: '晚上好，',
  loginTo: '欢迎登录{arg}',
  usdtAddressTips: '注意：转账时，请仔细核对此处的收款地址，与复制后的收款地址是否一致，以防您的剪贴板被木马篡改，造成损失。',
  whatsappService: '点击添加客服WhatsApp',
  telegramService: '点击添加客服Telegram',
  error400: '用户名或密码错误',
  changeLocaleTips: '切换语言会刷新界面，若界面一直出现白屏状态，请按{shortKey}可再次刷新界面！',
  numUnit: '个',
  toDownlaod: '立即下载',
  oldWindowsTips: 'win7/win8/win8.1/windows server 2012 已不再支持当前内核，请切换至104内核使用',
  quit: '退出软件',
  popTip: '弹出提示',
  securityTitle: '数据安全提示',
  securityContent:
    '比特浏览器最新版全面更新了加密策略，针对每一个窗口数据进行单独的密钥隔离，最大限度保护用户数据安全；<span style="text-decoration:underline">由于5.0.8及以下的老版本无法解密最新版的加密数据，已禁止掉老版本部分同步功能；强烈建议所有用户立即升级到最新版，以加强数据保护，以及使用更安全的同步功能！</span>',
  securityContent2:
    '对于Web3.0及对自己数据安全有担忧的用户，建议采取措施，如使用双重验证登录比特浏览器、修改网站密码、转移钱包资产等有效措施提升安全性！',
  securityContent3:
    '友情提醒：Web3.0、数字钱包用户，无论使用任何浏览器，都不建议开启“同步扩展应用数据”项，建议将扩展应用数据保存在本地最为安全！！同时删除云端扩展数据，降低未知的安全隐患！！！【比特浏览器的安全承诺：无论现在还是未来，只要新建窗口导入新钱包不开启扩展数据同步，就没有任何安全隐患】',
  chromeUpdateDetected: '检测到内核有更新，点击查看详情',
  chromeUpdateTitle: '检测到内核更新',
  chromeVersionUpdate: '{version}内核检测到有更新，点击立即更新',
  chromeUpdateError: '更新失败：{msg}',
  chromeUpdateSuccess: '内核 {version} 更新成功',
  ignoreUpdate: '本次升级不再提醒',
  themeDarkMode: '浅色主题/深色主题',
  copyPaymentUrl: '复制支付链接，使用Chrome浏览器打开进行支付',
  alipayQRTips: '请使用支付宝扫码支付',
  云手机环境: '云手机环境'
}
