export default {
  addTask: 'New Task',
  updateTask: 'Update RPA Task',
  bindBrowser: 'Bind Profile',
  workflowDashboard: 'Workflow Dashboard(With 112 Kernel)',
  taskConcurrent: 'Task Concurrent',
  taskConcurrentTips: '*How many browsers are open at the same time to execute the RPA task.',
  userManual: 'Docs',
  taskName: 'Task Name',
  workflowId: 'Workflow ID',
  type: 'Type',
  startTime: 'Start Time',
  endTime: 'End Time',
  remark: 'Remark',
  remarkPlaceholder: 'Please enter the task remark.',
  taskNameRule: 'Please enter the task name.',
  workflowRule: 'Please enter the workflow ID.',
  browserConcurrent: 'Concurrent',
  browserConcurrentRule: 'Enter the concurrent.',
  frequency: 'Frequency',
  frequencyRule: 'Please enter the frequency.',
  specifiedTime: 'Specified Time',
  timeInterval: 'Cycle Task',
  immediately: 'Immediately',
  day: 'Day',
  hour: 'Hours',
  minute: 'Minutes',
  selected: 'Selected',
  startTimeRule: 'Please select the start time.',
  startTimeRule2: 'The start time must be later than the current time.',
  endTimeRule: 'Please select the end time.',
  endTimeRule2: 'The end time must be later than the start time.',
  deleteTaskText: 'Confirm to delete the RPA task?',
  rpaStatus: 'Status',
  running: 'Running',
  pending: 'Waiting',
  disabled: 'Disabled',
  finished: 'Finished',
  concurrentPlaceholder: 'Enter the concurrent',
  concurrentValue: 'Task Concurrent',
  concurrentRule1: 'Enter the concurrent',
  runTask: 'Run RPA Task',
  stopTask: 'Stop RPA Task',
  deleteTask: 'Delete RPA Task',
  statusTooltip: 'Enable/Disable RPA Task',
  disableTask: 'Confirm to disable the RPA task?<p class="q-mt-sm text-red">When the task is disabled, the timer will no longer fire.</p>',
  enableTask: `Confirm to enable the RPA task?`,
  logs: 'Logs',
  logsTime: 'Execution Time',
  logContent: 'Execution Result',
  intervalPrepend: 'Execute every',
  intervalAppend: '',
  concurrentTips: 'How many tasks are allowed to run at the same time, please set it according to the performance of the computer!',
  success: 'Succeess.',
  bindError: 'Please bind at least one browser profile.',
  hasBind: 'Bound: ',
  bindNow: 'Bind',
  logsType: 'Type',
  openBrowser: 'Open Browser',
  openSkip: 'Browser Opened, SKip',
  openFailed: 'Browser Open Failed',
  closeBrowser: 'Close Browser',
  intNums: 'The input must be greater than 0.',
  skipTask: 'Task running, skip',
  enableRule: 'Please update task first, make sure that the start time is later than now.',
  timeout: 'Browser Close Timeout',
  timeoutTips: '* The opened browsers will auto close after this time.',
  timeoutRequired: 'Please enter the timeout.',
  timeoutNums: 'The input must be greater than or equal to 0.',
  seconds: 'Seconds',
  selectionTips:
    'Note: Bound profiles will be displayed at the top of the list, click the arrow icon to sort in reverse/forward order, and checked bound profiles and unchecked bound profiles will be sorted separately.',
  appendPageData: 'Select All On The Page',
  cancelPageData: 'Cancel All On The Page',
  cancelAll: 'Cancel All',
  taskSetting: 'Task Setting (Concurrent {arg})',
  taskSettingTitle: 'Task Setting',
  runningStrategy: 'Running Strategy',
  notOpened: 'The browsers has been opened to prohibit running the RPA task.',
  allCanRun: 'All browsers are allowed to run RPA tasks, including the opened.',
  allCanRunTips: 'The opened browsers will no longer trigger the "Browser Close Timeout" when running the RPA task!',
  runAtOpened: 'Run task at opened browser.',
  automaTips:
    'An upgrade to the embedded automa extension is scheduled for next Monday to Tuesday. We strongly recommend all RPA users to export their designed workflows as a backup to prevent any unexpected issues.',
  specifiedCore: 'With {version} Kernel'
}
